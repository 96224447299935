<template>
    <div>
        <modal
        class="new-modal-default"
        :name="modal_name"
        transition="nice-modal-fade"
        :delay="100"
        width="95%"
        :height="600"
        :pivot-y="0.5"
        :adaptive="true"
        :scrollable="true"
        >
            <div class="v-modal-content" style="background-color: #ffffff !important;height: inherit;">
                <div class="v-modal-header" style="background-color: #fff !important;">
                    <div>
                        <span class="v-modal-dialog-title text-secondary" style="color: #003cb9 !important;">Create Outlet</span>
                    </div>
                </div>
                <div class="v-modal-body">
                    <div class="v-modal-layout">
                        <div class="create-company-header d-flex align-items-center justify-content-between">
                            <span class="fs-14 pl-4 text-white">Outlet</span>
                            <span class="pointer" @click="expandTabs('outlet_info')">
                                <i class="text-white" :class="{'icon-chevron-double-up':outlet_info_expand, 'icon-chevron-double-down':!outlet_info_expand}" style="font-size: 20px;"></i>
                            </span>
                        </div>
                        <div :class="{'d-none':!outlet_info_expand}" class="px-4">
                            <div>
                                <div class="row">
                                    <div class="col-lg-4 col-md-6 col-sm-12 mt-6">
                                        <div class="w-100">
                                            <span class="input-label">Outlet Name<span class="pl-1 text-danger">*</span>
                                                <!-- <sup>
                                                    <el-tooltip class="item" effect="dark"
                                                        placement="top-start" content="Change options from settings">
                                                        <i class="icon-information text-secondary" style="font-size: 14px"></i>
                                                    </el-tooltip>
                                                </sup> -->
                                            </span>
                                            <div class="d-flex align-items-center mt-2">
                                                <input type="text" maxlength="30" v-validate="'required'" v-model="outlet_name" class="input-field input-field-size"
                                                    placeholder="Enter Outlet Name" name="outlet_name" />
                                            </div>
                                            <div class="text-right mr-4" style="position: absolute;right: 0;">
                                                <span class="input-field-length float-right">{{ outlet_name.length }}/30</span>
                                            </div>
                                            <span class="invalid-feedback-form text-danger" v-show="errors.has('outlet_name')">
                                                {{errors.first('outlet_name')}}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-12 mt-6">
                                        <div class="w-100">
                                            <span class="input-label">Outlet Type<span class="pl-1 text-danger">*</span>
                                                <!-- <sup>
                                                    <el-tooltip class="item" effect="dark"
                                                        placement="top-start" content="No Special Character except /_-">
                                                        <i class="icon-information text-secondary" style="font-size: 14px"></i>
                                                    </el-tooltip>
                                                </sup> -->
                                            </span>
                                            <div class="d-flex align-items-center mt-2">
                                                <input type="text" maxlength="30" v-on:keypress="outletTypeReg" v-model="outlet_type" v-validate="'required'" class="input-field input-field-size"
                                                    placeholder="Enter Outlet Type" name="outlet_type" />
                                            </div>
                                            <div class="text-right mr-4" style="position: absolute;right: 0;">
                                                <span class="input-field-length float-right">{{ outlet_type.length }}/30</span>
                                            </div>
                                            <!-- <span class="invalid-feedback-form text-danger" v-if="is_outlet_type">
                                                Special Characters are not allowed except /_-.
                                            </span> -->
                                            <span class="invalid-feedback-form text-danger" v-show="errors.has('outlet_type')">
                                                {{errors.first('outlet_name')}}
                                            </span>

                                            
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-12 mt-6">
                                        <div class="w-100">
                                            <span class="input-label">Date of Incorporation(DD/MM/YYYY)<span class="pl-1 text-danger">*</span></span>
                                            <div class="d-flex align-items-center mt-2">
                                                <date-picker class="profile-date-picker calendar-edit-per input-field input-field-size pt-0 pb-0 pr-0" v-model="date_of_incorporation" value-type="format" lang="en" :not-after="new Date()" 
                                                :clearable="false" style="padding-left: 0px !important;"
                                                :editable="false"
                                                type="date" format="DD/MM/YYYY"></date-picker>
                                            </div>
                                            <span class=" text-danger" style="position: absolute;" v-if="is_date_of_incorporation">
                                                Date of incorporation field is required.
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-12 mt-6">
                                        <div class="w-100">
                                            <span class="input-label">Time Zone<span class="pl-1 text-danger">*</span>
                                                    <sup>
                                                        <el-tooltip class="item" effect="dark"
                                                            placement="top-start" content="Change options from settings">
                                                            <i class="icon-information text-secondary" style="font-size: 14px"></i>
                                                        </el-tooltip>
                                                    </sup>
                                            </span>
                                            <div class="d-flex align-items-center mt-2">
                                                <multiselect class="diginew-multiselect multiselect-height-issue-fix" name="meetingcall" v-validate="'required'" label="label" :show-labels="false" :loading="loading" :searchable="true" :options="time_zone_options" v-model="time_zone" placeholder="Select Time Zone">
                                                    <template slot="noOptions">
                                                        <div class="text-secondary text-center fw-600">List is Empty</div>
                                                    </template>
                                                    <template slot="noResult">
                                                        <div class="text-secondary text-center fw-600">No Results Found</div>
                                                    </template>
                                                </multiselect>
                                                <span >
                                                    <i class="icon icon-check-circle fs-30 mt-9 ml-2" style="color: rgb(255, 154, 6);"></i>
                                                </span>
                                            </div>
                                            <span class=" text-danger" v-show="errors.has('time_zone')">
                                                {{errors.first('time_zone')}}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-12 mt-6">
                                        <div class="w-100">
                                            <span class="input-label">Number of Employees</span>
                                            <div class="d-flex align-items-center mt-2">
                                                <input type="text" maxlength="8" v-validate="{required: true,regex: /^[0-9]*$/ }" class="input-field input-field-size" v-model.number="no_of_employees" placeholder="Enter No of Employees" name="no_of_employees" />
                                            </div>
                                            <span class=" text-danger" v-show="errors.has('no_of_employees')">
                                                {{errors.first('no_of_employees')}}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-12 mt-6">
                                        <div class="position-relative">
                                            <span class="input-label">Is Outlet (s) ?
                                                <!-- <sup>
                                                    <el-tooltip class="item" effect="dark"
                                                        placement="top-start" content="Info">
                                                        <i class="icon-information text-secondary" style="font-size: 14px"></i>
                                                    </el-tooltip>
                                                </sup> -->
                                            </span>
                                            <div class="d-flex align-items-center ml-4 mt-4">
                                                <div class="d-flex align-items-center">
                                                    <input type="radio" id="branch" v-model="is_headquarters_and_branch" name="is_branch" value="branch">
                                                    <label class="ml-2 mb-0" for="branch">Branch</label>
                                                </div>
                                                <div class="d-flex align-items-center ml-6">
                                                    <input type="radio" id="outlet_yes" v-model="is_headquarters_and_branch" name="is_headquarters" value="headquarters">
                                                    <label class="ml-2 mb-0" for="outlet_yes">Headquarters</label><br>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-12 mt-6">
                                        <div class="w-100">
                                            <span class="input-label">Tax ID<span class="pl-1 text-danger">*</span></span>
                                            <div class="d-flex align-items-center mt-2">
                                                <input type="text" maxlength="20" class="input-field input-field-size" v-validate="'required'" v-model="tax_id" placeholder="Enter Tax ID" name="tax_id" />
                                            </div>
                                            <div >
                                                <span class="input-field-length float-right">{{ tax_id.length }}/20</span>
                                                <span class=" text-danger" v-show="errors.has('tax_id')">
                                                    {{errors.first('tax_id')}}
                                                </span>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-12 mt-6">
                                        <div class="w-100">
                                            <span class="input-label">Currency <span class="pl-1 text-danger">*</span>
                                            </span>
                                            <div class="d-flex align-items-center mt-2">
                                                <multiselect class="diginew-multiselect multiselect-height-issue-fix" label="label" v-validate="'required'" :show-labels="false" name="currency" :searchable="true" :options="currency_options" v-model="currency" placeholder="Select Currency">
                                                    <template slot="noOptions">
                                                        <div class="text-secondary text-center fw-600">List is Empty</div>
                                                    </template>
                                                    <template slot="noResult">
                                                        <div class="text-secondary text-center fw-600">No Results Found</div>
                                                    </template>
                                                </multiselect>
                                            </div>
                                            <span class=" text-danger" v-show="errors.has('currency')">
                                                {{errors.first('currency')}}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-6">
                                <div class="create-company-header d-flex align-items-center justify-content-between">
                                    <span class="fs-14 text-white">Outlet Communication Info</span>
                                    <span class="pointer" @click="expandTabs('communication_info')">
                                        <i class="text-white" :class="{'icon-chevron-double-up':communication_info_expand, 'icon-chevron-double-down':!communication_info_expand}" style="font-size: 20px;"></i>
                                    </span>
                                </div>
                                <div :class="{'d-none':!communication_info_expand}">
                                    <div class="row">
                                        <div class="col-lg-4 col-md-6 col-sm-12 mt-6">
                                            <div class="w-100">
                                                <span class="input-label d-flex align-items-center justify-content-between">
                                                    <span>
                                                        Outlet E-mail<span class="pl-1 text-danger">*</span>
                                                    </span>
                                                    <span class="d-flex pointer" @click="addAlternateEmail">
                                                        <i class="icon icon-plus-circle text-success"></i>
                                                    </span>
                                                </span>
                                                <div class="d-flex align-items-center">
                                                    <div class="d-flex align-items-center w-100">
                                                        <div class="d-flex align-items-center input-field input-field-size w-100">
                                                            <input type="text"
                                                                class="form-control-email-dropdown w-100"
                                                                v-model="outlet_email"
                                                                name="outlet_email"
                                                                v-validate="{required: true,regex: /^[a-zA-Z0-9-_.]+$/}"
                                                                autocomplete="off" maxlength="100"
                                                                placeholder="Enter Outlet E-mail" />
                                                            <span class="input-group-addon-email"
                                                                style="font-size: 14px;">@</span>
                                                            <span class="fs-14" style="">
                                                                {{company_outlet_domain_name.replace(/.+\/\/|www.|\+/g, '')}}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <span class=" text-danger" v-show="errors.has('outlet_email')">
                                                    {{errors.first('outlet_email')}}
                                                </span>
                                            </div>
                                        </div>
                                        <div v-for="(alt_email, alt_email_index) in outlet_alternate_email" :key="alt_email_index+'alt_email'" class="col-lg-4 col-md-6 col-sm-12 mt-6">
                                            <div class="w-100">
                                                <span class="input-label d-flex align-items-center justify-content-between">Alternative E-mail {{alt_email_index+1}}
                                                    <span class="pointer" @click="deleteAlternateEmail(alt_email_index)">
                                                        <i class="icon icon-minus-circle text-danger"></i>
                                                    </span>
                                                </span>
                                                <div class="d-flex align-items-center">
                                                    <input type="text" maxlength="100" v-validate="'email'" data-vv-as="Alternate Email" v-model="outlet_alternate_email[alt_email_index]" class="input-field input-field-size"
                                                        :placeholder="'Enter Alternative E-mail '+(alt_email_index+1)" :name="'outlet_alternate_email'+alt_email_index" />
                                                </div>
                                                <span class=" text-danger" v-show="errors.has('outlet_alternate_email'+alt_email_index)">
                                                    {{errors.first('outlet_alternate_email'+alt_email_index)}}
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-6 col-sm-12 mt-6">
                                            <span class="input-label">Outlet Phone Number<span class="pl-1 text-danger">*</span></span>
                                            <div class="d-flex align-items-center mt-2">
                                                <div class="leftType ml-0">
                                                    <div class="d-flex">
                                                        <multiselect v-validate="'required'" v-model="outlet_phone_type"
                                                            class="diginew-multiselect phone-number pointer multiselect-height-issue-fix" id="ajax"
                                                            :options="outlet_phone_type_options"
                                                            :multiple="false" :searchable="true" :internal-search="true"
                                                            :show-labels="false" style="width: 70px !important;height: 38px !important;" placeholder=""
                                                            :clear-on-select="false" :close-on-select="true" :options-limit="280"
                                                            :max-height="200" :show-no-results="true" :hide-selected="false">
                                                            <template slot="singleLabel" slot-scope="props">
                                                                <img class="option__image" :src="props.option.image" :alt="props.option.name" style="width: 22px;">
                                                            </template>
                                                            <template slot="option" slot-scope="{ option }">
                                                                <div class="d-flex flex-row">
                                                                    <img
                                                                        :src="option.image"
                                                                        style="width: 10px;" />
                                                                    <span class="ml-4">
                                                                        <span style="font-size: 14px">{{ option.name }}</span>
                                                                    </span>
                                                                </div>
                                                            </template>
                                                        </multiselect>
                                                    </div>
                                                </div>
                                                <div class="profile-primay-phone ml-2 w-100 input-field-size" style="position: relative;">
                                                    <div>
                                                        <vue-tel-input
                                                            name="outlet_phone"
                                                            :enabledCountryCode="false"
                                                            :disabledFormatting="false" :validCharactersOnly="true" :maxLen="15"
                                                            class="w-100 input-field-size" ref="telInput"
                                                            placeholder="Enter outlet Phone Number"
                                                            @onInput="outletsPhone($event)"
                                                            :defaultCountry="outlet_phone_number.country_code"
                                                            v-validate="{required: true,min:10}"
                                                            v-model="outlet_phone_number.phone_number">
                                                        </vue-tel-input>
                                                    </div>
                                                </div>
                                            </div>
                                            <span class=" text-danger" v-show="errors.has('outlet_phone')">
                                                {{errors.first('outlet_phone')}}
                                            </span>
                                        </div>
                                        <div v-for="(alterante_phone, alt_phone_index) in outlet_alternate_phone_number" :key="alt_phone_index+'alt'" class="col-lg-4 col-md-6 col-sm-12 mt-6">
                                            <span class="input-label d-flex align-items-center justify-content-between">Alternate Phone Number {{alt_phone_index+1}}
                                                <span v-if="alt_phone_index == 0" class="pointer" @click="addAlternatePhone">
                                                    <i class="icon icon-plus-circle text-success"></i>
                                                </span>
                                                <span v-else class="pointer" @click="deleteAlternatePhone">
                                                    <i class="icon icon-minus-circle text-danger"></i>
                                                </span>
                                            </span>
                                            <div class="d-flex align-items-center">
                                                <div class="leftType ml-0">
                                                    <div class="d-flex">
                                                        <multiselect v-model="alterante_phone.phone_type"
                                                            class="diginew-multiselect phone-number pointer multiselect-height-issue-fix" id="ajax"
                                                            :options="outlet_phone_type_options"
                                                            :multiple="false" :searchable="true" :internal-search="true"
                                                            :show-labels="false" style="width: 70px !important;height: 38px !important;" placeholder=""
                                                            :clear-on-select="false" :close-on-select="true" :options-limit="280"
                                                            :max-height="200" :show-no-results="true" :hide-selected="false">
                                                            <template slot="singleLabel" slot-scope="props">
                                                                <img class="option__image" :src="props.option.image" :alt="props.option.name" style="width:22px">
                                                            </template>
                                                            <template slot="option" slot-scope="{ option }">
                                                                <div class="d-flex flex-row">
                                                                    <img
                                                                        :src="option.image"
                                                                        style="width: 10px;" />
                                                                    <span class="ml-4">
                                                                        <span style="font-size: 14px">{{ option.name }}</span>
                                                                    </span>
                                                                </div>
                                                            </template>
                                                        </multiselect>
                                                    </div>
                                                </div>
                                                <div class="profile-primay-phone ml-2 w-100" style="position: relative;">
                                                    <div>
                                                        <vue-tel-input
                                                            :name="'outlet_alternate_phone'+alt_phone_index"
                                                            :enabledCountryCode="false"
                                                            :disabledFormatting="false" :validCharactersOnly="true" :maxLen="15"
                                                            class="w-100 input-field-size" ref="telInput"
                                                            :placeholder="'Enter Alternate Phone Number ' + (alt_phone_index+1)"
                                                            data-vv-as="Alternate Phone"
                                                            @onInput="outletsAltPhone($event, alt_phone_index)"
                                                            :defaultCountry="alterante_phone.country_code"
                                                            v-validate="{min:10}"
                                                            v-model="alterante_phone.phone_number">
                                                        </vue-tel-input>
                                                    </div>
                                                </div>
                                            </div>
                                            <span class=" text-danger" v-show="errors.has('outlet_alternate_phone'+alt_phone_index)">
                                                {{errors.first('outlet_alternate_phone'+alt_phone_index)}}
                                            </span>
                                        </div>
                                        <div class="col-lg-4 col-md-6 col-sm-12 mt-6">
                                            <div class="w-100">
                                                <span class="input-label">Website<span class="pl-1 text-danger">*</span></span>
                                                <div class="d-flex align-items-center mt-2">
                                                    <input type="text" maxlength="100" v-validate="{required: true, regex: /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/}" v-model="outlet_website" class="input-field input-field-size"
                                                        placeholder="Enter Website" name="outlet_website" />
                                                </div>
                                                <span class=" text-danger" v-show="errors.has('outlet_website')">
                                                    {{errors.first('outlet_website')}}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-5">
                                <div class="create-company-header d-flex align-items-center justify-content-between">
                                    <span class="fs-14 text-white">Outlet Address</span>
                                    <span class="pointer" @click="expandTabs('outlet_address')">
                                        <i class="text-white" :class="{'icon-chevron-double-up':outlet_address_expand, 'icon-chevron-double-down':!outlet_address_expand}" style="font-size: 20px;"></i>
                                    </span>
                                </div>
                                <div :class="{'d-none':!outlet_address_expand}">
                                    <div>
                                        <div class="row">
                                            <div class="col-lg-4 col-md-6 col-sm-12 mt-6">
                                                <span class="input-label">Location<span class="pl-1 text-danger">*</span></span>
                                                <div class="d-flex justify-content-between align-items-center mt-2">
                                                    <div class="form-group gMap pt-0 mb-0" style="position:relative;width:100%;">
                                                        <gmap-autocomplete
                                                            class="input-field mt-0"
                                                            id="autocompleteInput"
                                                            name="get_location"
                                                            v-validate="'required'"
                                                            placeholder="Search Location"
                                                            @place_changed="setPlace($event)"
                                                            :value="outlet_address.location_vmodel"
                                                            v-model="outlet_address.location_vmodel"
                                                            style="padding: 8px 32px 8px 8px !important; height: 40px;"
                                                        ></gmap-autocomplete>
                                                        <img
                                                            src="/static/images/marker_pin_new.svg"
                                                            style="position: absolute;right: 12px;bottom: 10px;"
                                                        />
                                                    </div>
                                                    <div class="form-group gMap ml-4 pt-0 mb-0" style="cursor: pointer;" @click="getLocation()">
                                                        <img src="/static/images/gps_new_white_back.svg" style="height: 40px;" />
                                                    </div>
                                                </div>
                                                <span class=" text-danger" style="position: absolute;" v-show="errors.has('get_location')">
                                                    {{errors.first('get_location')}}
                                                </span>
                                            </div>
                                            <div class="col-lg-4 col-md-6 col-sm-12 mt-6">
                                                <div class="w-100">
                                                    <span class="input-label">Building No.</span>
                                                    <div class="d-flex align-items-center mt-2">
                                                        <input type="text" maxlength="10" v-model="outlet_address.building_no" class="input-field input-field-size"
                                                            placeholder="Enter Building No" name="building_no" />
                                                    </div>
                                                    <div class="text-right mr-4" style="position: absolute;right: 0px;">
                                                        <span class="input-field-length">{{ (outlet_address.building_no).length }}/10</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-6 col-sm-12 mt-6">
                                                <div class="w-100">
                                                    <span class="input-label">Street 1<span class="pl-1 text-danger">*</span></span>
                                                    <div class="d-flex align-items-center mt-2">
                                                        <input type="text" maxlength="150" v-validate="'required'" v-model="outlet_address.street1" class="input-field input-field-size"
                                                            placeholder="Enter Street 1" name="street1" />
                                                    </div>
                                                    <div class="text-right mr-4" style="position: absolute;right: 0;">
                                                        <span class="input-field-length">{{ (outlet_address.street1).length }}/150</span>
                                                    </div>
                                                    <span class="invalid-feedback-form text-danger" v-show="errors.has('street1')">
                                                        {{errors.first('street1')}}
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-6 col-sm-12 mt-6">
                                                <div class="w-100">
                                                    <span class="input-label">Street 2</span>
                                                    <div class="d-flex align-items-center mt-2">
                                                        <input type="text" maxlength="150" v-model="outlet_address.street2" class="input-field input-field-size"
                                                            placeholder="Enter Street 2" name="street2" />
                                                    </div>
                                                    <div class="text-right mr-4" style="position: absolute;right: 0px;">
                                                        <span class="input-field-length">{{ (outlet_address.street2).length }}/150</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-6 col-sm-12 mt-6">
                                                <div class="w-100">
                                                    <span class="input-label">City<span class="pl-1 text-danger">*</span></span>
                                                    <div class="d-flex align-items-center mt-2">
                                                        <input type="text" maxlength="150" v-validate="'required'" v-model="outlet_address.city" class="input-field input-field-size"
                                                            placeholder="Enter City" name="city" />
                                                    </div>
                                                    <div class="text-right mr-4" style="position: absolute;right: 0;">
                                                        <span class="input-field-length">{{ (outlet_address.city).length }}/150</span>
                                                    </div>
                                                    <span class="invalid-feedback-form text-danger" v-show="errors.has('city')">
                                                        {{errors.first('city')}}
                                                    </span>
                                                   
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-6 col-sm-12 mt-6">
                                                <div class="w-100">
                                                    <span class="input-label">Township<span class="pl-1 text-danger">*</span></span>
                                                    <div class="d-flex align-items-center mt-2">
                                                        <input type="text" maxlength="150" v-validate="'required'" v-model="outlet_address.township" class="input-field input-field-size"
                                                            placeholder="Enter Township" name="township" />
                                                    </div>
                                                    <div class="text-right mr-4" style="position: absolute;right: 0;">
                                                        <span class="input-field-length">{{ (outlet_address.township).length }}/150</span>
                                                    </div>
                                                    <span class="invalid-feedback-form text-danger" v-show="errors.has('township')">
                                                        {{errors.first('township')}}
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-6 col-sm-12 mt-6">
                                                <div class="w-100">
                                                    <span class="input-label">State<span class="pl-1 text-danger">*</span></span>
                                                    <div class="d-flex align-items-center mt-2">
                                                        <input type="text" maxlength="150" v-validate="'required'" v-model="outlet_address.state" class="input-field input-field-size"
                                                            placeholder="Enter State" name="state" />
                                                    </div>
                                                    <div class="" >
                                                        <span class=" text-danger" v-show="errors.has('state')">
                                                            {{errors.first('state')}}
                                                        </span>
                                                        <span class="input-field-length float-right">{{ (outlet_address.state).length }}/150</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-6 col-sm-12 mt-6">
                                                <span class="input-label">Country Name<span class="pl-1 text-danger">*</span></span>
                                                <multiselect
                                                    :max-height="200"
                                                    name="country_name"
                                                    class="diginew-multiselect mt-2 w-100 multiselect-height-issue-fix"
                                                    v-model="outlet_address.country"
                                                    :options="countries"
                                                    @close="countryClose(outlet_address.country)"
                                                    :searchable="true"
                                                    :close-on-select="true"
                                                    v-validate="'required'"
                                                    :show-labels="false"
                                                    placeholder="Select Country"
                                                >
                                                <!-- @input="outlet_address.state = '', outlet_address.city = '',outlet_address.street1 = '', outlet_address.street2 = '',outlet_address.township = '',outlet_address.zip_code = '',outlet_address.location_vmodel = ''" -->
                                                    <template slot="noOptions">
                                                        <div class="text-secondary text-center fw-600">List is Empty</div>
                                                    </template>
                                                    <template slot="noResult">
                                                        <div class="text-secondary text-center fw-600">No Results Found</div>
                                                    </template>
                                                </multiselect>
                                                <div>
                                                <span class=" text-danger" v-show="errors.has('country_name')">
                                                    {{errors.first('country_name')}}
                                                </span>
                                                <span class="input-field-length float-right">{{ (outlet_address.country).length }}/230</span>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-6 col-sm-12 mt-6">
                                                <div class="w-100">
                                                    <span class="input-label">ZipCode<span class="pl-1 text-danger">*</span></span>
                                                    <div class="d-flex align-items-center mt-2">
                                                        <input type="text" maxlength="10" v-validate="'required|numeric'"  v-model="outlet_address.zip_code" class="input-field input-field-size"
                                                            placeholder="Enter ZipCode" name="zip_code" />
                                                    </div>
                                                    <div class="" >
                                                        <span class=" text-danger" v-show="errors.has('zip_code')">
                                                            {{errors.first('zip_code')}}
                                                        </span>
                                                        <!-- <span class="input-field-length float-right">{{ (outlet_address.zip_code).length }}/20</span> -->
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="v-modal-dialog-actions">
                    <div>
                        <h1 v-if="error_text" class="fs-14 text-danger text-center">{{error_text}}</h1>
                        <div class="d-flex align-items-center justify-content-center pb-8">
                            <button class="btn btn-outline-secondary" :disabled="save_create_btn_hide" @click="hideCreateOutlet" style="width: 120px;border-radius: 5px;">Cancel</button>
                            <button class="btn btn-new-success ml-5" :disabled="save_create_btn_hide"  @click="addOutlet" style="width: 120px;border-radius: 5px;">Save
                                <hollow-dots-spinner v-if="save_create_btn_hide" style="position: absolute;width: 160px !important;margin: 0;top: 12px;left: -15px;display: flex;justify-content: center;" :animation-duration="1200" :dot-size="10" :dots-num="3" :color="'#00448b'" />
                            </button>
                        </div>
                        <!-- <div class="d-flex justify-content-center align-items-center my-6">
                            <button class="btn btn-new-danger btn-smm mr-3" @click="hideCreateOutlet">CANCEL</button>
                            <button class="btn btn-new-green btn-smm ml-3" :disabled="save_create_btn_hide" @click="addOutlet">SAVE</button>
                        </div> -->
                    </div>
                </div>
            </div>
        </modal>
        <sweet-modal ref="success_modal" overlay-theme="dark" icon="success">{{success_msg}}</sweet-modal>
        <sweet-modal ref="warning_modal" overlay-theme="dark" icon="warning">{{warning_msg}}</sweet-modal>
    </div>
</template>

<script>
import { HollowDotsSpinner } from "epic-spinners";
import countries from "../../json/countries.json";
import currencies from '../../json/currency.json';
import timezone from '../../json/timezone.json';
import companies from '../../mixins/companies';
import axios from 'axios';
import globals from '../../globals';
import { SweetModal } from 'sweet-modal-vue'
export default {
    data() {
        return {
            save_create_btn_hide: false,
            success_msg: "",
            warning_msg: "",
            outlet_info_expand: true,
            outlet_address_expand: true,
            communication_info_expand: true,
            industry_type_options: [],
            time_zone_options: timezone,
            company_outlet_domain_name: "",
            time_zone: {},
            outlet_name: '',
            outlet_type: '',
            outlet_alternate_email: [],
            outlet_email: '',
            date_of_incorporation: '',
            no_of_employees: 0,
            loading: false,
            outlet_phone_number: {
                phone_number: '',
                country_code: 'US',
                dial_code: '1',
            },
            outlet_website: '',
            outlet_phone_type: {
                name: 'Work',
                image: 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/work-contact-icon.svg'
            },
            outlet_alternate_phone_number: [
                {
                    phone_type: {
                        name: 'Work',
                        image: 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/work-contact-icon.svg'
                    },
                    phone_number: "",
                    country_code: "US",
                    dial_code: "1"
                }
            ],
            outlet_alternate_phone_type: {
                name: 'Work',
                image: 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/work-contact-icon.svg'
            },
            outlet_phone_type_options: [
                {
                    name: 'Home',
                    image: 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/home-contact-icon.svg'
                },
                {
                    name: 'Mobile',
                    image: 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/mobile-contact-icon.svg'
                },
                {
                    name: 'Whatsapp',
                    image: 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/whatsapp-contact-icon.svg'
                },
                {
                    name: 'Line',
                    image: 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/line-contact-icon.svg'
                },
                {
                    name: 'Work',
                    image: 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/work-contact-icon.svg'
                },
                {
                    name: 'Telegram',
                    image: 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/telegram-contact-icon.svg'
                },
                {
                    name: 'Others',
                    image: 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/others-contact-icon.svg'
                },
                {
                    name: 'Messenger',
                    image: 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/messenger-contact-icon.svg'
                },
                {
                    name: 'Fax',
                    image: 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/fax-contact-icon.svg'
                }
            ],
            is_headquarters_and_branch: 'branch',
            currency: '',
            tax_id: '',
            countries: countries,
            currency_options: currencies,
            outlet_address: {
                building_no: "",
                street1: "",
                street2: "",
                city: "",
                township: "",
                state: "",
                country: "",
                zip_code: "",
                address_type: "Current Address",
                location_vmodel: ""
            },
            is_outlet_type: false,
            is_date_of_incorporation: false,
            error_text: ''
        }
    },
    props: ["modal_name"],
    components: {
        SweetModal, HollowDotsSpinner
    },
    mixins: [companies],
    methods: {
        outletTypeReg() {
            let reg = /^[^*|\":<>[\]{}`\\()';@&$]+$/;
            if(!reg.test(val)) {
                this.is_outlet_type = true;
            } else {
                this.is_outlet_type = false;
            }
        },
        hideCreateOutlet() {
            setTimeout(() => {
                this.$modal.hide(this.modal_name);
            }, 500);
            this.$emit("hideCreateOutlet");
        },
        hideCreateOutletSucc(){
            setTimeout(() => {
                this.$modal.hide(this.modal_name);
            }, 500);
            this.$emit("hideCreateOutletSuccess");
        },
        saveCreateOutlet() {

        },
        outletsPhone({number,isValid,country}) {
            this.outlet_phone_number.phone_number = number.national;
            this.outlet_phone_number.dial_code = country.dialCode;
            this.outlet_phone_number.country_code = country.iso2;
        },
        outletsAltPhone({number,isValid,country}, phoneindex) {
            this.outlet_alternate_phone_number[phoneindex].phone_number = number.national;
            this.outlet_alternate_phone_number[phoneindex].dial_code = country.dialCode;
            this.outlet_alternate_phone_number[phoneindex].country_code = country.iso2;
        },
        expandTabs(type) {
            if(type == 'outlet_info') {
                this.outlet_info_expand = !this.outlet_info_expand;
            } else if(type == 'communication_info') {
                this.communication_info_expand = !this.communication_info_expand;
            } else if(type == 'outlet_address') {
                this.outlet_address_expand = !this.outlet_address_expand;
            }
        },
        addAlternatePhone() {
            this.outlet_alternate_phone_number.push(
                {
                    phone_type: {
                        name: 'Work',
                        image: 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/work-contact-icon.svg'
                    },
                    phone_number: "",
                    country_code: "US",
                    dial_code: "1"
                }
            );
        },
        deleteAlternatePhone(index) {
            this.outlet_alternate_phone_number.splice(index, 1);
        },
        addAlternateEmail() {
            this.outlet_alternate_email.push("");
        },
        deleteAlternateEmail(index) {
            this.outlet_alternate_email.splice(index, 1);
        },
        countryClose(data) {
            this.outlet_address.country = data;
            this.$forceUpdate();
        },
        setPlace(place) {
            let address = place.formatted_address.split(", ");
            this.outlet_address.street1 =
                address[address.length - 6] || "";
            this.outlet_address.township =
                address[address.length - 4] || "";
            this.outlet_address.city = address[address.length - 3] || "";
            let statezip = address[address.length - 2]
                ? address[address.length - 2].split(" ")
                : ["", ""];
            if (statezip.length == 1) {
                if (statezip[0].match(/^[0-9]+$/)) {
                this.outlet_address.zip_code = statezip[0];
                } else {
                this.outlet_address.state = statezip[0];
                }
            } else {
                this.outlet_address.zip_code = statezip[0].match(/^[0-9]+$/)
                ? statezip[0]
                : statezip[1].match(/^[0-9]+$/)
                ? statezip[1]
                : "";
                this.outlet_address.state = statezip[0].match(/^[a-zA-Z]+$/)
                ? statezip[0]
                : statezip[1].match(/^[a-zA-Z]+$/)
                ? statezip[1]
                : "";
            }
            if (address[address.length - 1].includes("-")) {
                let countrySelected = address[address.length - 1].split(" - ");
                countrySelected.forEach(x => {
                if (this.countries.includes(x)) {
                    this.outlet_address.country = x;
                } else {
                    this.outlet_address.country = "";
                }
                });
            } else {
                this.outlet_address.country = address[address.length - 1];
            }
            // this.outlet_address.address.latitude = currentPlace.geometry.location.lat() || "";
            // this.outlet_address.address.longitude = currentPlace.geometry.location.lng() || "";
            this.outlet_address.location_vmodel = place.formatted_address;
            this.$forceUpdate();
        },
        getLocation() {
            // this.addressIndex = index;
            if (navigator.geolocation) {
                // timeout at 60000 milliseconds (60 seconds)
                var options = {
                enableHighAccuracy: false,
                timeout: 30000, // milliseconds (30 seconds)
                maximumAge: 600000
                }; // milliseconds (10 minutes)
                navigator.geolocation.getCurrentPosition(
                this.showLocation,
                this.errorHandler,
                options
                );
            } else {
                alert("Sorry, browser does not support geolocation!");
            }
        },
        showLocation(position) {
            this.latitude = position.coords.latitude;
            this.longitude = position.coords.longitude;
            let latlongvalue = position.coords.latitude + "," + position.coords.longitude;
            let calllatlong = axios.create();
            delete calllatlong.defaults.headers.common['Authorization'];
            calllatlong.post("https://maps.googleapis.com/maps/api/geocode/json?latlng=" + latlongvalue + "&key=AIzaSyCQSEwELtCrUrLSfsqU0wX7QAHbLVQzUrY")
            .then(response => {
                let address = response.data.results[0].formatted_address.split(", ");
                this.outlet_address.street1 =
                address[address.length - 6] || "";
                this.outlet_address.township =
                address[address.length - 4] || "";
                this.outlet_address.city = address[address.length - 3] || "";
                let statezip = address[address.length - 2]
                ? address[address.length - 2].split(" ")
                : ["", ""];
                this.outlet_address.zip_code = statezip[0].match(/^[0-9]+$/)
                ? statezip[0]
                : statezip[1].match(/^[0-9]+$/)
                ? statezip[1]
                : "";
                this.outlet_address.state = statezip[0].match(/^[a-zA-Z]+$/)
                ? statezip[0]
                : statezip[1].match(/^[a-zA-Z]+$/)
                ? statezip[1]
                : "";
                if (address[address.length - 1].includes("-")) {
                let countrySelected = address[address.length - 1].split(" - ");
                countrySelected.forEach(x => {
                    if (this.countries.includes(x)) {
                    this.outlet_address.country = x;
                    } else {
                    this.outlet_address.country = "";
                    }
                });
                } else {
                this.outlet_address.country = address[address.length - 1];
                }
                this.outlet_address.location_vmodel = response.data.results[0].formatted_address;
                this.$forceUpdate();
            })
            .catch(e => {
                // this.errors.push(e)
            });
        },
        errorHandler(err) {
            if (err.code == 1) {
                // alert("Error: Access is denied!");
            } else if (err.code == 2) {
                // alert("Error: Position is unavailable!");
            }
        },
        async fetchDropdownsData(type) {
            this.loading = true;
            try {
                let param = type;
                let response = await this.getDropdownsData(param);
                this.loading = false;
                if(response.status_id == 1) {
                    if(type == 'industry_type') {
                        this.industry_type_options = response.response;
                    }
                }
            }
            catch(err) {
                this.loading = false;
            }
        },
        async createNewOutlet() {
            this.save_create_btn_hide = true;
            await this.$http.post(globals.AUTH_SERVICE + `/outlets/`, this.create_data).then((response) => {
                if(response.data.status_id == 1) {
                    this.success_msg = response.data.message
                    this.$refs.success_modal.open();
                    setTimeout(() => {
                        this.$refs.success_modal.close();
                        this.hideCreateOutletSucc();
                    }, 2000);
                    
                }
            }).catch((err) => {
                this.warning_msg = err.response.data.reason;
                    this.$refs.warning_modal.open();
                    setTimeout(() => {
                        this.save_create_btn_hide = false;
                        this.$refs.warning_modal.close();
                    }, 3000);
            });
        },
        addOutlet() {
            let reg = /^[^*|\":<>[\]{}`\\()';@&$]+$/;
            this.$validator.validateAll().then(result => {
                // && reg.test(this.outlet_type)
                if (result == true && this.date_of_incorporation !== '') {
                    this.is_outlet_type = false;
                    this.is_date_of_incorporation = false;
                    this.create_data.alternate_phone_numbers.forEach((ph, index) => {
                        ph.dial_code = ph.phone_number.split(" ")[0] || "+91";
                        ph.phone_type = ph.phone_type.name;
                    })
                    this.createNewOutlet();
                } else {
                    // if(!reg.test(this.outlet_type)) {
                    //     this.is_outlet_type = true;
                    // } 
                    if(this.date_of_incorporation == '' || this.date_of_incorporation === undefined || this.date_of_incorporation == null) {
                        this.is_date_of_incorporation = true;
                    }
                }
            })
        },
    },
    mounted() {
        let tz = new Date().getTimezoneOffset().toString();
        if (tz == "-330") {
            this.time_zone = {
                label: "(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi (Asia/Kolkata)",
                value: "Asia/Kolkata",
            };
        } else if (tz == "-420") {
            this.time_zone = {
                label: "(GMT+07:00) Bangkok, Hanoi, Jakarta (Asia/Bangkok)",
                value: "Asia/Bangkok",
            };
        }
        this.company_outlet_domain_name = localStorage.getItem("company_outlet_domain_name");
    },
    watch: {
        // "outlet_type": function(val) {
        //     let reg = /^[^*|\":<>[\]{}`\\()';@&$]+$/;
        //     if(!reg.test(val)) {
        //         this.is_outlet_type = true;
        //     } else {
        //         this.is_outlet_type = false;
        //     }
        // },
        "date_of_incorporation": function(val) {
            if(val == '' || val === undefined || val == null) {
                this.is_date_of_incorporation = true;
            } else {
                this.is_date_of_incorporation = false;
            }
        }
    },
    computed: {
        loggedInUser() {
            return this.$store.getters.loggedInUser;
        },
        // account() {
        //     return this.$store.getters.account;
        // },
        // selectedCompany() {
        //     return this.$store.getters.selectedCompany;
        // },
        create_data() {
            return {
                outlet_name: this.outlet_name,
                company_id: this.$route.params.company_id,
                outlet_type: this.outlet_type,
                date_of_incorporation: this.date_of_incorporation ? this.date_of_incorporation.split("/")[2]+"-"+this.date_of_incorporation.split("/")[1]+"-"+this.date_of_incorporation.split("/")[0] : "",
                time_zone: this.time_zone.label,
                no_of_employees: this.no_of_employees,
                is_outlet: this.is_headquarters_and_branch,
                tax_id: this.tax_id,
                currency: this.currency,
                email: this.outlet_email+'@'+this.company_outlet_domain_name.replace(/.+\/\/|www.|\+/g, ''),
                alternate_email: this.outlet_alternate_email,
                website: this.outlet_website,
                location: this.outlet_address.location_vmodel,
                building_no: this.outlet_address.building_no,
                street1: this.outlet_address.street1 || "",
                street2: this.outlet_address.street2 || "",
                city: this.outlet_address.city,
                township: this.outlet_address.township || "",
                state: this.outlet_address.state,
                country: this.outlet_address.country,
                zip_code: this.outlet_address.zip_code || "",
                primary_number: {
                    phone_type: this.outlet_phone_type.name,
                    phone_number: this.outlet_phone_number.phone_number,
                    country_code: this.outlet_phone_number.country_code,
                    dial_code: this.outlet_phone_number.dial_code
                },
                alternate_phone_numbers: this.outlet_alternate_phone_number
            }
        }
    },
    created() {
        const dict = {
            custom: {
                outlet_name:{
                    required:() => "Outlet name is required",
                },
                outlet_type:{
                    required:() => "Outlet type is required",
                    regex:() => "Special Characters are not allowed except /_-.",
                },
                time_zone:{
                    required:() => "Time zone is required",
                },
                get_location:{
                    required:() => "Location is required",
                },
                no_of_employees:{
                    regex:() => "Please enter a valid number",
                },
                tax_id: {
                    required:() => "Tax id is required",
                },
                currency: {
                    required:() => "Currency is required",
                },
                outlet_email: {
                    required:() => "Outlet email is required",
                    regex: () => "Enter valid email address.",
                },
                outlet_phone: {
                    required:() => "Outlet phone is required",
                    min: () => "Phone Number must contain more than 9 numbers.",
                },
                outlet_website: {
                    required:() => "Outlet website is required",
                    regex:() => "Enter valid outlet website",
                    // alpha_dash:() => "Enter valid outlet website",
                },
                country_name:{
                    required:() => "Country is required",
                },
                street1:{
                    required:() => "Street1 is required",
                },
                township:{
                    required:() => "Township is required",
                },
                state:{
                    required:() => "State is required",
                },
                city:{
                    required:() => "City is required",
                },
                zip_code:{
                    required:() => "Zip Code is required",
                    numeric:() => "Please enter a valid zip code",
                }
            }
        }
        this.$validator.localize('en', dict)
    }
}
</script>
<style scoped>
	.v-modal-header {
		display: -webkit-box;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
		-webkit-box-pack: justify;
		-webkit-justify-content: center;
		-ms-flex-pack: justify;
		justify-content: center;
        color: #00448b !important;
		padding: 15px;
		border-bottom: 0px solid #E9ECEF;
		-webkit-border-top-left-radius: 15px;
		border-top-left-radius: 15px;
		-webkit-border-top-right-radius: 15px;
		border-top-right-radius: 15px;
		background-color: #f5f6f9 !important;
	}

	.v-modal-content {
		position: relative;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		width: 100%;
		pointer-events: auto;
		background-color: #fff;
		-webkit-background-clip: padding-box;
		background-clip: padding-box;
		border: 0px solid rgba(0, 0, 0, 0.2);
		-webkit-border-radius: 2px;
		border-radius: 2px;
		-webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
		box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
		outline: 0;
	}

	.v-modal-dialog-title.v-modal-title {
		letter-spacing: .005em;
	}

	.v-modal-dialog-title {
		margin-bottom: 0px !important;
		font-size: 15px !important;
		font-weight: 600 !important;
		font-style: normal;
		display: block;
		font-stretch: normal;
		line-height: normal;
		color: #2b6ad0 !important;
		text-transform: uppercase !important;
		margin: 0 auto !important;
		padding: 4px !important;
	}

	.v-modal-body {
		overflow: auto;
		width: 100%;
		position: relative;
		-webkit-box-flex: 1;
		-webkit-flex: 1 1 auto;
		-ms-flex: 1 1 auto;
		flex: 1 1 auto;
		padding: 15px;
	}

	.new-modal-default .v-modal-header {
		background-color: #005dae !important;
		height: 50px;
        color: #fff;
        display: flex;
	}

    .v-modal-dialog-title {
        color: #fff !important;
    }

	/* .v-modal-layout {
			display: flex;
			flex-wrap: wrap;
			width: 100%;
		} */
	.v-modal-dialog-actions {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		padding: 15px;
		border-top: 0px solid transparent;
	}
    .create-company-header {
        padding: 9.9px 15px;
        background-color: #00448b;
        margin-left: -20px;
        margin-right: -15px;
    }
    .btn-outline-secondary {
        color: #e82828;
        border: 1px solid #e82828 !important;
    }
    .btn-outline-secondary:hover {
        color: #fff;
        background-color: #e82828;
        border-color: #e82828 !important;
    }
    .input-field-size{
        height: 38px;
    }
</style>